import React from "react"
import Layout from "../_components/Layout/Layout"
import MainTitle from "../_components/MainTitle/MainTitle"
import Banner from "../_components/Banner/Banner"
import RewardsCarousel from "../_components/RewardsCarousel/RewardsCarousel"
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn"
import PageSummary from "../_components/PageSummary/PageSummary"

const EventPromotions = () => {
    return (
        <Layout title="Some treats waiting for you">
            <div className="custom-bg events">
                <div className="bg">
                    <img src={"https://assets.xela.co/taste/taa/2090c8ce-5444-4c3a-9643-d57d7df55458.png"} alt="bg" />
                </div>
                <MainTitle>Some treats <br />waiting for you</MainTitle>

                <PageSummary className="text">
                    <span className="text-center">Here are some of the gifts you might unlock after registering your details.</span>
                </PageSummary>

                <ClaimRewardBtn />
            </div>

            <div className="banners">
                <Banner
                    image="https://assets.xela.co/taste/taa/33dd567d-1180-406d-b1b1-8fb7c2d4aa26.png"
                    text="WIN A TRIP TO LAKE COMO at the PORETTI STAND"
                    newClass="yellow"
                />

                <Banner
                    image="https://assets.xela.co/taste/taa/f2767beb-d647-45ef-8fd0-3abc2544f109.png"
                    text="Claim a Perfect Draft Pro Machine & Leffe bundle"
                />

                <Banner
                    image="https://assets.xela.co/taste/taa/8a09f968-0aaa-48ea-887c-9060403d4e74.png"
                    text="Unlock A stylish illy coffee machine & a year’s supply of coffee"
                />
            </div>

            <RewardsCarousel />

            <ClaimRewardBtn className="py-4" />
        </Layout>
    )
}

export default EventPromotions
